<template>
  <section class="px-3 pb-1">
    <Header
      :title="titleHeader"
      :token="showToken"
      :background="showBg"
      :coin="coin"
    />
    <div class="pt-6">
    </div>
    <div class="pt-0">
      <b-row class="justify-content-center h-100 mx-0">
        <b-col align-self="center" class="p-0">
          <b-card
            no-body
            v-for="(list, index) in rewardList"
            :key="`reward-${index}`"
            class="text-center round-15 mb-3"
          >
            <div class="bg-winner round-15">
              <div class="position-relative w-100 px-3 pt-3">
                <b-img
                  fluid
                  alt="Vue logo"
                  :src="list.banner"
                  class="mb-3 w-100"
                >
                </b-img>
                <h5 class="m-1 font-weight-bold">{{ list.title }}</h5>
                <!-- <h5 class="m-1 font-weight-bold">{{ list.subTitle }}</h5> -->
                <p class="text-black-50">{{ list.priode }}</p>
              </div>
            </div>
            <b-card-footer class="bg-transparent">
              <b-link :to="`/reward/${list.id}`" class="text-black-50">Detail Informasi</b-link>
            </b-card-footer>
          </b-card>
        </b-col>
      </b-row>
    </div>
  </section>
</template>

<script>
// @ is an alias to /src
import Header from "@/components/Header.vue";
import { mapActions, mapState } from "vuex";
export default {
  name: "Leaderboard",
  components: {
    Header,
  },
  data() {
    return {
      titleHeader: "Informasi Reward",
      showToken: false,
      showBg: true,
      // lists: [
      //   {
      //     id: 1,
      //     title: "Pesta Reward",
      //     subTitle: "Priode 1",
      //     priode: "5 Jan 2021 - 25 Jan 2021",
      //     banner: "",
      //   },
      //   {
      //     id: 2,
      //     title: "Pesta Reward",
      //     subTitle: "Priode 2",
      //     priode: "5 Feb 2021 - 25 Feb 2021",
      //     banner: "",
      //   },
      //   {
      //     id: 3,
      //     title: "Pesta Reward",
      //     subTitle: "Priode 3",
      //     priode: "5 Mar 2021 - 25 Mar 2021",
      //     banner: "",
      //   },
      // ],
    };
  },
  beforeMount() {
    this.listReward()
  },
  mounted() {
    // console.log(this.$store.state.reward);
  },
  computed: {
    ...mapState(["coin", "rewardList"]),
  },
  methods: {
    ...mapActions([
      'listReward'
    ])
  }
};
</script>

<style>
body {
  background-color: white;
}
</style>
<style scoped>
.leaderboard {
  margin-top: 7.5rem;
}
.actives {
  background-color: #fff !important;
  font-weight: 600;
}
.bg-burem {
  background-color: #eff0f6 !important;
}
.text-burem a {
  color: #a0a3bd !important;
}
.text-hitam a {
  color: #212121 !important;
}
.round-15 {
  border-radius: 15px;
}
</style>
