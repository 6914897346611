<template>
<section class="px-3 pb-1">
  <Header :title="titleHeader"  :token="showToken" :background="showBg" :coin="coin" />
  <div class="pt-6">
    <!-- <b-row class="mx-0">
      <b-col class="p-0">
        <b-nav pills justified class="p-1 bg-burem rounded">
          <b-nav-item to="/leaderboard" class="actives text-hitam shadow-sm rounded">Leaderboard</b-nav-item>
          <b-nav-item to="/redeem" class="text-burem">Redeem</b-nav-item>
        </b-nav>
      </b-col>
    </b-row> -->
</div>
  <div class="pt-0">
    <b-alert show variant="info" class="px-3">
      <div class="d-flex align-items-center">
      <b-icon icon="brightness-high-fill" font-scale="0.7"></b-icon>
      <p class="text-left info-text pl-2 m-0">
        Pemenang yang memiliki skor tertinggi akan dihubungi oleh pihak Customer Service kami.
      </p>
      </div>
  </b-alert>
  <div class="pt-0 position-relative">
    <b-img fluid alt="Vue logo" :src="require('@/assets/pesta-reward.png')" class="mb-3 w-100 px-2">
    </b-img>
    <div class="position-absolute w-100 bottom-25">
      <vue-countdown v-if="counting" :time="time" :interval="100" @end="onCountdownEnd" v-slot="{ days, hours, minutes, seconds }">
        <div class="d-flex justify-content-center px-1 text-danger bg-redup py-2 mx-3 rounded">
          <b-col sm="4" class="timer-text bg-white mx-1 p-1 rounded">{{ days }} Hari</b-col>
          <b-col sm="4" class="timer-text bg-white mx-1 p-1 rounded">{{ hours }} Jam</b-col>
          <b-col sm="4" class="timer-text bg-white mx-1 p-1 rounded">{{ minutes }} Menit</b-col>
          <b-col sm="4" class="timer-text bg-white mx-1 p-1 rounded">{{ seconds }} Detik</b-col>
        </div>
      </vue-countdown>
    </div>
  </div>
  <div class="tab-leaderboard">
    <b-tabs content-class="mt-3" justified>
      <b-tab title="Peserta" active>
        <ListLeaderboard :listBoard="tcache" />
      </b-tab>
      <b-tab title="Pemenang">
        <ListWinner :dataWinner="listWinnerData"/>
      </b-tab>
    </b-tabs>
  </div>
  </div>
    <Skor />
  </section>
</template>

<script>
// @ is an alias to /src
import ListLeaderboard from '@/components/ListLeaderboard.vue'
import ListWinner from '@/components/ListWinner.vue'
import Header from '@/components/Header.vue'
import Skor from '@/components/Skor.vue'
import { mapActions, mapState } from 'vuex'
import VueCountdown from '@chenfengyuan/vue-countdown'
export default {
  name: 'Leaderboard',
  components: {
    ListLeaderboard,
    ListWinner,
    Header,
    Skor,
    VueCountdown,
  },
  data() {
    return {
      titleHeader: "Leaderboard",
      showToken: false,
      showBg: true,
      time: 0,
      tcache:[],
      tmkey : "628xxxxxxxxxx",
      dm:1,
      counting: false,
      listWinnerData:{
        title:"Untitle",
        priode:"Untitle",
        winner:[]
      },
      monthNames:["Januari", "Februari", "Maret", "April", "Mei", "Juni", "Juli", "Augustus", "September", "Oktober", "November", "Desember"]
      // totalCoin: this.$store.state.coin,
      // dataList: [
      //   {
      //     msisdn: "62895345xxxx",
      //     point: "10000"
      //   },
      //   {
      //     msisdn: "62895111xxxx",
      //     point: "9000"
      //   },
      //   {
      //     msisdn: "62896443xxxx",
      //     point: "8000"
      //   },
      //   {
      //     msisdn: "62895645xxxx",
      //     point: "7000"
      //   },
      //   {
      //     msisdn: "62896349xxxx",
      //     point: "6000"
      //   },
      //   {
      //     msisdn: "62896340xxxx",
      //     point: "5000"
      //   },
      //   {
      //     msisdn: "62896340xxxx",
      //     point: "4500"
      //   },
      //   {
      //     msisdn: "62895040xxxx",
      //     point: "3000"
      //   },
      //   {
      //     msisdn: "62895390xxxx",
      //     point: "2500"
      //   },
      //   {
      //     msisdn: "62896140xxxx",
      //     point: "1000"
      //   },
      // ]
    };
  },
  beforeMount() {
    this.listWinner().then(
    (data) => {
        // console.log("Then success:" + message);
        this.listWinnerData.priode=this.getPriode(data.start_date,data.end_date)
        this.listWinnerData.title=data.event_sub_name
        this.listWinnerData.winner=data.winners
    }
)
  },
  mounted() {
    this.timer();
    this.tcache=[];
    this.changeMsisdn(this.leaderboardShadow);
    const newList = this.leaderboardList.slice(0,5);
    this.changeMsisdn(newList);
    // console.log(newList)
  },
  updated() {
    // this.listWinnerData.priode=this.getPriode(this.winnerList.start_date,this.winnerList.end_date)
    this.listWinnerData.title=this.winnerList.event_sub_name
    this.listWinnerData.winner=this.winnerList.winners
  },
  computed: {
    ...mapState([
      'coin',
      'leaderboardList',
      'winnerList',
      'leaderboardShadow',
    ]),
  },
  methods: {
    ...mapActions([
      'listWinner',
    ]),
    timer() {
      const times = '2022-02-28 21:00:00'
      const now = new Date();
      // const newYear = new Date(now.getFullYear() + 1, 0, 1);
      const newYear = new Date(times);
      this.time = newYear - now
      // this.time = Date.parse(times)
      if (this.time >= 0) {
        console.log('start')
        this.startCountdown()
      }
    },
    startCountdown () {
      this.counting = true;
    },
    onCountdownEnd () {
      this.counting = false;
    },
    getPriode (dStart, dEnd){
      // let dateStart = dStart.split("-");
      let dateEnd = dEnd.split("-");
      // console.log(dateEnd)
      // let monsStart = this.monthNames[(parseInt(dateStart[1])-1)]
      // let monsEnd = this.monthNames[(parseInt(dateEnd[1])-1)]
      // let goBetween = monsStart+" - "+monsEnd+" "+dateEnd[0]

      // return goBetween
      return dateEnd[0]
    },
    changeMsisdn(obj){
      obj.forEach((val) => 
        {
          let cloneVal = Object.assign({}, val);
          var rs = this.tmkey.slice(0, this.dm+4);
          var ws = cloneVal.msisdn.toString();
          ws=ws.slice(this.dm+4,this.dm+8);
          var bs=this.tmkey.slice(this.dm+8,cloneVal.msisdn.lenght);
          var bls = rs+ws+bs;
          cloneVal.msisdn=bls;
          this.tcache.push(cloneVal);
          this.dm++;
          if(this.dm==6)this.dm=1;
        }
      );
    }
  }
}
</script>

<style>
body {
    background-color: white;
}
</style>
<style scoped>
.leaderboard {
  margin-top: 7.5rem;
}
.actives {
  background-color: #fff !important;
  font-weight: 600;
}
.bg-burem {
  background-color: #EFF0F6 !important;
}
.text-burem a{
  color: #A0A3BD !important;
}
.text-hitam a{
  color: #212121 !important;
}
.timer-text {
  font-size: .6rem;
  font-weight: 700;
}
.bg-redup {
  background-color: rgba(250, 250, 250, .7);
}
.bottom-25 {
  bottom: 25px;
}
.info-text {
  font-size: .65rem;
}
/deep/ .nav-tabs .nav-link.active, /deep/ .nav-tabs .nav-item.show .nav-link {
    color: #212121;
    background-color: transparent !important;
    border-color: transparent;
    border-bottom: 1px solid #212121 !important;
}
/deep/ .nav-tabs .nav-link{
    color: #ababab;
    background-color: transparent !important;
    border-color: transparent;
    border-bottom: 1px solid #ababab !important;
}
</style>
