<template>
  <div>
    <b-row class="justify-content-center h-100 mx-0">
      <!-- <b-col md="12" align-self="center"> -->
      <!-- <b-card-group colums> -->
      <!-- <b-row 
            class="justify-content-center"> -->

      <b-col align-self="center" class="p-0">
        <b-card no-body
         v-for="(list, index) in dataWinner.winner"
        :key="`winner-${index}`"
        class="text-center round-15 mb-3">
          <div class="bg-winner text-light round-15">
            <div class="position-relative w-100 p-3">
              <b-img
                :src="require('@/assets/frame-title.png')"
                alt="Media Aside"
                class="w-100"
              ></b-img>
              <div class="content-title position-absolute">
                <h2 class="m-0 font-weight-bolder">{{dataWinner.title}}</h2>
                <h6 class="font-weight-bolder">{{dataWinner.priode}}</h6>
              </div>
            </div>
            <b-img
              :src="require('@/assets/frame-reward.png')"
              alt="Media Aside"
            ></b-img>
            <h4 class="m-1 font-weight-bold">{{list.name}}</h4>
            <h6 class="font-weight-bolder">{{list.prize_name}}</h6>
            <h6>{{changeMsisdn(list.msisdn)}}</h6>
          </div>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>
<script>
export default {
  name: "Listboard",
  data() {
    return {
      lists: [],
      tmkey : "628xxxxxxxxxx",
      dm:1,
    };
  },
  props: ["dataWinner"],
  updated() {
    this.lists = this.dataWinner.winner
  },
  methods: {
    changeMsisdn(val){
      var rs = this.tmkey.slice(0, this.dm+4);
      var ws = val.toString();
      ws=ws.slice(this.dm+4,this.dm+8);
      var bs=this.tmkey.slice(this.dm+8,ws.lenght);
      var bls = rs+ws+bs;
      return bls;
    }
  }
};
</script>
<style scoped>
.round-15 {
  border-radius: 15px;
}
.content-title {
    top: 1.5rem;
    width: 100%;
    text-align: center;
    left: 0px;
    /* font-weight: bolder !important; */
    color: #ac570e;
}
.content-title h4, .content-title h6 {
  line-height: 1.1;
}
.content-title h4 {
  font-size: 1.3rem;
}
.content-title h6 {
  font-size: .8rem;
}
.bg-winner {
  background: linear-gradient(130.56deg, #F11240 4.66%, #BF0244 97.17%);
}
</style>
