import { render, staticRenderFns } from "./ListWinner.vue?vue&type=template&id=f0c1ec9a&scoped=true&"
import script from "./ListWinner.vue?vue&type=script&lang=js&"
export * from "./ListWinner.vue?vue&type=script&lang=js&"
import style0 from "./ListWinner.vue?vue&type=style&index=0&id=f0c1ec9a&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f0c1ec9a",
  null
  
)

export default component.exports